import { nextTick } from "vue";
import { createI18n } from "vue-i18n";

import en from "./en.json";
import hi from "./hi.json";
import vn from "./vn.json";
import id from "./id.json";
import my from "./my.json";
import th from "./th.json";
import tl from "./tl.json";
import tr from "./tr.json";

export const SUPPORT_LOCALES = ["en", "hi", "vn", "id", "my", "th", "tl", "tr"];

const browserLang = navigator.language.split("-")[0];
const selectedLang = SUPPORT_LOCALES.includes(browserLang) ? browserLang : "en";

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: selectedLang,
  fallbackLocale: "en",
  messages: { en, hi, vn, id, my, th, tl, tr },
});

export function setI18nLanguage(locale) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }

  document.querySelector("html").setAttribute("lang", locale);
}

export async function loadLocaleMessages(locale) {
  if (!SUPPORT_LOCALES.includes(locale)) {
    console.warn(`Локаль ${locale} не поддерживается!`);
    return nextTick();
  }

  if (!i18n.global.availableLocales.includes(locale)) {
    try {
      const messages = await import(
        /* @vite-ignore */
        `${locale}.json`
      );

      i18n.global.setLocaleMessage(locale, messages.default);
    } catch (error) {
      console.error(`Ошибка загрузки локали ${locale}:`, error);
      return nextTick();
    }
  }

  setI18nLanguage(locale);

  return nextTick();
}

export default i18n;
