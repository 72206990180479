<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div
    class="sm: relative flex h-[140vw] flex-col items-center justify-between text-[#FAFAFA] sm:h-[690px] xl:h-[83vh] 2xl:h-[80.25vh]"
  >
    <img
      src="/img/bg/001.png"
      alt="main img"
      class="absolute z-0 h-full w-full object-cover sm:h-[700px] xl:h-[80vh] xl:object-contain 2xl:-m-[10vh] 2xl:h-[100vh]"
    />
    <div class="h-750-mt z-10 flex flex-col items-center justify-center">
      <img
        src="/img/logo.svg"
        alt="logo"
        class="z-10 h-12 w-40 xl:h-16 xl:w-52"
      />
      <div
        class="font-gilroy mt-4 px-2 text-center text-xl text-[28px] leading-[32px] font-bold uppercase xl:w-[1198px] xl:text-4xl xl:leading-[48px]"
      >
        {{ t("title") }}
      </div>
    </div>
    <div
      class="relative -bottom-9 z-10 mt-10 flex flex-col items-center sm:-bottom-2"
    >
      <div class="flex items-center border-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="41"
          height="44"
          viewBox="0 0 41 44"
          fill="none"
          class="-mr-[1px]"
        >
          <path d="M40.5 0V44H0.5L40.5 0Z" fill="#D9D9D9" />
          <path
            d="M40.5 0V44H0.5L40.5 0Z"
            fill="url(#paint0_linear_2972_9927)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2972_9927"
              x1="20.5"
              y1="0"
              x2="20.5"
              y2="44"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#016AB4" />
              <stop offset="1" stop-color="#01356E" />
            </linearGradient>
          </defs>
        </svg>
        <span
          class="flex h-11 items-center justify-center bg-gradient-to-b from-[#016AB4] to-[#01356E] p-3 text-base xl:text-2xl"
          >{{ t("text") }}</span
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="41"
          height="44"
          viewBox="0 0 41 44"
          fill="none"
          class="-ml-[1px]"
        >
          <path d="M0.5 0V44H40.5L0.5 0Z" fill="#D9D9D9" />
          <path
            d="M0.5 0V44H40.5L0.5 0Z"
            fill="url(#paint0_linear_2972_9931)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2972_9931"
              x1="20.5"
              y1="0"
              x2="20.5"
              y2="44"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#016AB4" />
              <stop offset="1" stop-color="#01356E" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <a
        :href="t('buttonLink')"
        class="flex w-[95vw] max-w-[400px] flex-col items-center justify-center gap-[10px] rounded-lg border-2 border-[#8B4021] bg-gradient-to-r from-[#FFEA8C] via-[#FDF7C3] to-[#DAA142] p-[16px_10px] shadow-[0px_0px_12px_0px_rgba(243,175,48,0.80)] hover:cursor-pointer hover:border-[#8B4021] hover:bg-gradient-to-r hover:from-[#FFF8D8] hover:via-[#FEFCE9] hover:to-[#F3E1C1] hover:shadow-[0px_0px_24px_0px_#F3AF30] active:border-[#8B4021] active:bg-gradient-to-r active:from-[#EDCF97] active:via-[#FDF5D5] active:to-[#FFF1B9] active:shadow-[0px_0px_24px_0px_#F3AF30] xl:max-w-[500px]"
      >
        <span class="text-xl font-bold text-[#002F5E] uppercase xl:text-[32px]">
          {{ t("button") }}
        </span>
      </a>
    </div>
  </div>
</template>

<style>
@media (max-height: 750px) {
  .h-750-mt {
    margin-top: -40px;
  }
}
</style>
