<script setup>
import LangSwitch from "@components/header/langSwitch/langSwitch.vue";
import MainBlock from "@components/body/mainBlock.vue";
import { ref, onMounted } from "vue";

const isLoaded = ref(false);

onMounted(async () => {
  isLoaded.value = true;
});
</script>

<template>
  <div
    v-if="isLoaded"
    class="relative min-h-screen w-full bg-gradient-to-b from-[#000742] to-[#00011D] font-[Gilroy] text-[#FAFAFA]"
  >
    <header class="flex w-full justify-end">
      <LangSwitch />
    </header>
    <main>
      <MainBlock />
    </main>
  </div>
</template>
