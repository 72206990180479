<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { loadLocaleMessages } from "@i18n";

// Локали с соответствующими флагами
const LANGUAGES = [
  { code: "en", name: "EN", flag: "/img/flags/en.svg" },
  { code: "hi", name: "HI", flag: "/img/flags/hi.svg" },
  { code: "vn", name: "VN", flag: "/img/flags/vn.svg" },
  { code: "id", name: "ID", flag: "/img/flags/id.svg" },
  { code: "my", name: "MY", flag: "/img/flags/my.svg" },
  { code: "th", name: "TH", flag: "/img/flags/th.svg" },
  { code: "tl", name: "TL", flag: "/img/flags/tl.svg" },
  { code: "tr", name: "TR", flag: "/img/flags/tr.svg" },
];

const { locale } = useI18n();
const isDropdownOpen = ref(false);
const dropdownRef = ref(null);

// Вычисляемые свойства
const arrowClass = computed(() =>
  isDropdownOpen.value ? "rotate-0" : "rotate-180",
);

// Исключаем текущий язык из списка
const filteredLanguages = computed(() =>
  LANGUAGES.filter((lang) => lang.code !== locale.value),
);

// Функция смены языка
async function changeLanguage(lang) {
  await loadLocaleMessages(lang);
  locale.value = lang;
  isDropdownOpen.value = false; // Закрываем дропдаун после выбора
}

// Закрываем дропдаун при клике вне его области
function handleClickOutside(event) {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isDropdownOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<template>
  <div
    ref="dropdownRef"
    class="relative z-50 inline-block p-4 text-left font-medium"
  >
    <!-- Кнопка переключения -->
    <button
      @click="isDropdownOpen = !isDropdownOpen"
      class="flex w-20 items-center gap-1 rounded-lg bg-white/6 p-2 text-white backdrop-blur-[24px]"
    >
      <span v-if="LANGUAGES.find((l) => l.code === locale)?.flag">
        <img
          :src="LANGUAGES.find((l) => l.code === locale).flag"
          alt="`flag ${lang.name}`"
        />
      </span>
      <span class="uppercase">{{ locale }}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        :class="['transition-transform duration-300', arrowClass]"
      >
        <path
          d="M8 14L12 10L16 14"
          stroke="#FAFAFA"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>

    <!-- Выпадающее меню -->
    <div
      v-show="isDropdownOpen"
      class="absolute mt-1 w-20 overflow-hidden rounded-lg bg-white/6 text-white shadow-[0px_4px_8px_0px_rgba(4,12,52,0.48)] backdrop-blur-[24px]"
    >
      <ul>
        <li
          v-for="(lang, index) in filteredLanguages"
          :key="lang.code"
          @click="changeLanguage(lang.code)"
          class="border-opacity-10 flex cursor-pointer items-center gap-2 border-t border-white/10 p-2 text-white hover:bg-white/16"
          :class="{ 'border-t-0': index === 0 }"
        >
          <span><img :src="lang.flag" alt="`flag ${lang.name}`" /></span>
          <span>{{ lang.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
